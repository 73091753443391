import React from "react";
import theme from "theme";
import { Theme, Text, Box, Icon, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { BsCircle } from "react-icons/bs";
import { GiCircle } from "react-icons/gi";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Pristine Shine El Araba Yıkama
			</title>
			<meta name={"description"} content={"Her Detayda Mükemmellik"} />
			<meta property={"og:title"} content={"Pristine Shine El Araba Yıkama"} />
			<meta property={"og:description"} content={"Her Detayda Mükemmellik"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/668d05098d4a0c00203182a2/images/1-4.jpeg?v=2024-07-09T11:20:49.950Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/668d05098d4a0c00203182a2/images/1-4.jpeg?v=2024-07-09T11:20:49.950Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/668d05098d4a0c00203182a2/images/1-4.jpeg?v=2024-07-09T11:20:49.950Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/668d05098d4a0c00203182a2/images/1-4.jpeg?v=2024-07-09T11:20:49.950Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/668d05098d4a0c00203182a2/images/1-4.jpeg?v=2024-07-09T11:20:49.950Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/668d05098d4a0c00203182a2/images/1-4.jpeg?v=2024-07-09T11:20:49.950Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/668d05098d4a0c00203182a2/images/1-4.jpeg?v=2024-07-09T11:20:49.950Z"} />
			<meta name={"msapplication-TileColor"} content={"#000848"} />
		</Helmet>
		<Components.Header>
			<Override slot="link1">
				Ana sayfa
			</Override>
		</Components.Header>
		<Components.Hero12>
			<Override slot="text1">
				Hizmet Tekliflerimiz
			</Override>
			<Override slot="text">
				Her Detayda Mükemmellik
			</Override>
			<Override slot="link" href="/">
				Ana sayfa
			</Override>
			<Override slot="link1" href="/contact" />
			<Override slot="image" src="https://uploads.quarkly.io/668d05098d4a0c00203182a2/images/2-3.jpeg?v=2024-07-09T11:20:49.926Z" border-radius="30px" />
		</Components.Hero12>
		<Section
			padding="88px 0 88px 0"
			sm-padding="60px 0 60px 0"
			box-shadow="--m"
			background="--color-light"
			md-padding="45px 0 45px 0"
			quarkly-title="Advantages/Features-25"
		>
			<Override slot="SectionContent" max-width="1220px" sm-min-width="280px" />
			<Box
				width="100%"
				flex-direction="column"
				md-width="100%"
				md-align-items="center"
				md-justify-content="center"
				margin="0px 0px 64px 0px"
				display="flex"
				lg-width="100%"
				align-items="center"
				lg-margin="0px 0px 48px 0px"
				md-margin="0px 0px 24px 0px"
			>
				<Text
					color="--darkL2"
					text-align="center"
					lg-width="100%"
					margin="0px 0px 0px 0px"
					font="--lead"
					width="70%"
					lg-max-width="720px"
				>
					Pristine Shine Hand Car Wash'da, tüm araç bakımı ihtiyaçlarınızı karşılamak için tasarlanmış birinci sınıf hizmetler sunuyoruz. Amacımız, aracınızın yepyeni görünmesini ve hissetmesini sağlayan eşsiz bir elde araba yıkama deneyimi sunmaktır. Kapsamlı hizmet yelpazemiz, ister hızlı bir yıkamaya ister ayrıntılı bir temizliğe ihtiyacınız olsun, yanınızda olmamızı sağlar.
				</Text>
			</Box>
			<Box
				display="grid"
				lg-flex-wrap="wrap"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="24px"
				lg-grid-gap="16px"
				md-grid-template-columns="1fr"
				margin="0px 0px 2rem 0px"
			>
				<Box
					display="flex"
					padding="46px 46px 47px 46px"
					background="#FFFFFF"
					flex-direction="column"
					justify-content="flex-start"
					box-shadow="--l"
					align-items="center"
					lg-padding="24px 24px 24px 24px"
					border-radius="12px"
				>
					<Icon
						margin="0px 0px 30px 0px"
						color="--orange"
						size="64px"
						category="bs"
						icon={BsCircle}
						lg-font="48px sans-serif"
					/>
					<Text font="--lead" margin="0px 0px 18px 0px" color="--darkL2" text-align="center">
						Dış Cephe El Yıkama
					</Text>
					<Text
						flex="1 0 auto"
						margin="0px 0px 0px 0px"
						color="--darkL2"
						font="--base"
						text-align="center"
					>
						Dış cephe elde yıkama hizmetimiz, aracınızın boyasını korurken kiri ve kiri gidermek için tasarlanmıştır.
Nazik Elde Yıkama: Yumuşak eldivenler ve çevre dostu sabunlar kullanarak aracınızı yüzeyini çizmeden iyice temizliyoruz.
Durulama ve Kurutma: Su lekelerini önlemek için dikkatli bir durulama ve ardından mikrofiber havluyla elle kurulama.
Tekerlek ve Lastik Temizliği: Tekerlekleri ve lastikleri temizleyerek fren tozunu ve yoldaki kirleri gideren özel ürünler ve fırçalar.
					</Text>
				</Box>
				<Box
					justify-content="flex-start"
					flex-direction="column"
					padding="46px 46px 47px 46px"
					background="#FFFFFF"
					display="flex"
					box-shadow="--l"
					align-items="center"
					lg-padding="24px 24px 24px 24px"
					border-radius="12px"
				>
					<Icon
						icon={GiCircle}
						margin="0px 0px 30px 0px"
						color="--orange"
						size="64px"
						category="gi"
						lg-font="48px sans-serif"
					/>
					<Text color="--darkL2" font="--lead" margin="0px 0px 18px 0px" text-align="center">
						İç Detaylandırma
					</Text>
					<Text
						font="--base"
						flex="1 0 auto"
						margin="0px 0px 0px 0px"
						color="--darkL2"
						text-align="center"
					>
						Detaylı temizlik hizmetlerimizle aracınızın iç kısmının tertemiz görünmesini sağlayın.
Elektrikleme: Toz ve kalıntıları gidermek için halıların, koltukların ve bagajın iyice süpürülmesi.
Kontrol Paneli ve Konsol Temizliği: Parlamayı geri kazandırmak ve parmak izlerini ve lekeleri gidermek için silme ve cilalama.
Koltuk Temizliği: Deri veya kumaş koltukların derinlemesine temizlenmesi, leke ve kokulardan arındırılması.
					</Text>
				</Box>
				<Box
					display="flex"
					background="#FFFFFF"
					justify-content="flex-start"
					flex-direction="column"
					padding="46px 46px 47px 46px"
					box-shadow="--l"
					align-items="center"
					lg-padding="24px 24px 24px 24px"
					border-radius="12px"
				>
					<Icon
						size="64px"
						category="gi"
						icon={GiCircle}
						margin="0px 0px 30px 0px"
						color="--orange"
						lg-font="48px sans-serif"
					/>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--lead" text-align="center">
						Balmumu ve Cila
					</Text>
					<Text
						flex="1 0 auto"
						margin="0px 0px 0px 0px"
						color="--darkL2"
						font="--base"
						text-align="center"
					>
						Cila ve cila hizmetimizle aracınızın boyasını koruyun ve ona göz kamaştırıcı bir parlaklık kazandırın.
El Cilası Uygulaması: Boyayı korumak ve parlaklığı arttırmak için yüksek kaliteli cilanın elle uygulanması.
Parlatma: Pürüzsüz, parlak bir yüzey elde etmek için nazik parlatma.
Boya Sızdırmazlık Maddesi: Arabanızın aylarca harika görünmesini sağlayan uzun süreli koruma.
					</Text>
				</Box>
				<Box
					justify-content="flex-start"
					flex-direction="column"
					padding="46px 46px 47px 46px"
					background="#FFFFFF"
					display="flex"
					box-shadow="--l"
					align-items="center"
					lg-padding="24px 24px 24px 24px"
					border-radius="12px"
				>
					<Icon
						icon={GiCircle}
						margin="0px 0px 30px 0px"
						color="--orange"
						size="64px"
						category="gi"
						lg-font="48px sans-serif"
					/>
					<Text color="--darkL2" font="--lead" margin="0px 0px 18px 0px" text-align="center">
						Özel Hizmetler
					</Text>
					<Text
						font="--base"
						flex="1 0 auto"
						margin="0px 0px 0px 0px"
						color="--darkL2"
						text-align="center"
					>
						Özel ihtiyaçları karşılamak ve aracınızın görünümünü iyileştirmek için özel hizmetler sunuyoruz.
Kil Çubuğu İşlemi: Pürüzsüz bir yüzey elde etmek için kirletici maddelerin boya yüzeyinden çıkarılması.
Koku Giderme: İç mekandaki hoş olmayan kokuları ortadan kaldırmak için ileri tekniklerin kullanılması.
Far Onarımı: Sisli veya sararmış farlara netlik ve parlaklık kazandırma.
					</Text>
				</Box>
			</Box>
			<Box
				display="block"
				lg-flex-wrap="wrap"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="24px"
				lg-grid-gap="16px"
				md-grid-template-columns="1fr"
			>
				<Box
					display="flex"
					padding="46px 46px 47px 46px"
					background="#FFFFFF"
					flex-direction="column"
					justify-content="flex-start"
					box-shadow="--l"
					align-items="center"
					lg-padding="24px 24px 24px 24px"
					border-radius="12px"
				>
					<Icon
						margin="0px 0px 30px 0px"
						color="--orange"
						size="64px"
						category="gi"
						icon={GiCircle}
						lg-font="48px sans-serif"
					/>
					<Text font="--lead" margin="0px 0px 18px 0px" color="--darkL2" text-align="center">
						Tam Detaylandırma Paketleri
					</Text>
					<Text
						flex="1 0 auto"
						margin="0px 0px 0px 0px"
						color="--darkL2"
						font="--base"
						text-align="center"
					>
						Araç bakımında en üst noktaya ulaşmak için eksiksiz detaylandırma paketlerimiz kapsamlı bir iç ve dış temizlik sunar.
Tam Dış ve İç Temizlik: Kapsamlı bir temizlik için tüm dış ve iç hizmetlerimizi birleştirir.
Motor Bölmesi Temizliği: Daha iyi performans ve görünüm için motor bölmesindeki kiri ve kiri temizler.
Cam İşleme: Netliği sağlamak ve parlamayı azaltmak için tüm pencerelerin temizlenmesi ve işlenmesi.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 0px"
				justify-content="center"
				lg-order="1"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline2"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Tüm Tekliflerimizi Keşfedin
				</Text>
				<Text margin="0px 0px 40px 0px" color="--greyD3" font="--lead" lg-text-align="center">
					Ve bu sadece başlangıç. Hizmetlerimiz, tüm araç bakım ihtiyaçlarınızı en üst düzeyde kalite ve detaylara gösterilen özenle karşılamak üzere tasarlanmıştır. Tekliflerimiz hakkında daha fazla bilgi edinmek veya özel gereksinimlerinizi görüşmek için sizi bizimle iletişime geçmeye veya konumumuzu ziyaret etmeye davet ediyoruz. Sorularınız için size yardımcı olmak veya bir sonraki hizmetinizi planlamak için buradayız.
				</Text>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 32px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 32px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://uploads.quarkly.io/668d05098d4a0c00203182a2/images/1-1.jpeg?v=2024-07-09T11:20:49.917Z"
					object-fit="cover"
					width="100%"
					height="100%"
					border-radius="24px"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					min-height="600px"
					max-width="580px"
					sm-min-height="100vw"
					srcSet="https://smartuploads.quarkly.io/668d05098d4a0c00203182a2/images/1-1.jpeg?v=2024-07-09T11%3A20%3A49.917Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/668d05098d4a0c00203182a2/images/1-1.jpeg?v=2024-07-09T11%3A20%3A49.917Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/668d05098d4a0c00203182a2/images/1-1.jpeg?v=2024-07-09T11%3A20%3A49.917Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/668d05098d4a0c00203182a2/images/1-1.jpeg?v=2024-07-09T11%3A20%3A49.917Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/668d05098d4a0c00203182a2/images/1-1.jpeg?v=2024-07-09T11%3A20%3A49.917Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/668d05098d4a0c00203182a2/images/1-1.jpeg?v=2024-07-09T11%3A20%3A49.917Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/668d05098d4a0c00203182a2/images/1-1.jpeg?v=2024-07-09T11%3A20%3A49.917Z&quality=85&w=3200 3200w"
					sizes="(max-width: 460px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6123949e957019001e284456"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});